import axios from 'axios'

import { getLocalStorageToken } from '@/services'

export default () => {
  let config = {
    baseURL: `${window.location.origin === 'http://localhost:8080' ? 'https://admin.stage.cor2ed.com' : window.location.origin}/api/`
  };

  let instance = axios.create(config);
  const token = getLocalStorageToken()

  if (token) {
    instance.defaults.headers.common['Authorization'] = "Bearer " + token;
  }

  return instance;
};
