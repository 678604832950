<template>
  <v-navigation-drawer
    height="100vh"
    style="min-width:256px;background-color: #477995;"
    dark
    permanent
  >
    <v-list>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="deep-orange--text">COR2ED CMS</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item link v-on:click="$router.push('/').catch(()=>{})">
        <v-list-item-icon>
          <v-icon>mdi-view-dashboard</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Dashboard</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item link v-on:click="$router.push('/therapeutic-area').catch(()=>{})">
        <v-list-item-icon>
          <v-icon>mdi-account</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Therapeutic Areas</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item link v-on:click="$router.push('/labels').catch(()=>{})">
        <v-list-item-icon>
          <v-icon>mdi-label-multiple</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Labels</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      
      <v-list-item link v-on:click="$router.push('/programmes').catch(()=>{})">
        <v-list-item-icon>
          <v-icon>mdi-notebook-edit</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Programmes</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item link v-on:click="$router.push('/audit-logs').catch(()=>{})">
        <v-list-item-icon>
          <v-icon>mdi-gavel</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Audit logs</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="deep-orange--text">SITE MEMBERS</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item link v-on:click="$router.push('/experts').catch(()=>{})">
        <v-list-item-icon>
          <v-icon>mdi-account-tie</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Experts</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item link v-on:click="$router.push('/team-members').catch(()=>{})">
        <v-list-item-icon>
          <v-icon>mdi-home-account</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Team members</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item link v-on:click="$router.push('/users').catch(()=>{})">
        <v-list-item-icon>
          <v-icon>mdi-account-group</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Registered users</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="deep-orange--text">PAGES</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

       <v-list-item link v-on:click="$router.push('/privacy-policy-page').catch(()=>{})">
        <v-list-item-icon>
          <v-icon>mdi-account-eye</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Privacy policy</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item link v-on:click="$router.push('/medical-affairs-page').catch(()=>{})">
        <v-list-item-icon>
          <v-icon>mdi-medical-bag</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Medical affairs</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      
      <v-list-item link v-on:click="$router.push('/about-us-page').catch(()=>{})">
        <v-list-item-icon>
          <v-icon>mdi-information</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>About Us</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="deep-orange--text">COMPONENTS</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item link v-on:click="$router.push('/accreditations').catch(()=>{})">
        <v-list-item-icon>
          <v-icon>mdi-archive-arrow-down</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Accreditations</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item link v-on:click="$router.push('/partnerships').catch(()=>{})">
        <v-list-item-icon>
          <v-icon>mdi-handshake</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Partnerships</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item link v-on:click="$router.push('/collaborations').catch(()=>{})">
        <v-list-item-icon>
          <v-icon>mdi-account-switch</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Collaborations</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item link v-on:click="$router.push('/testimonials').catch(()=>{})">
        <v-list-item-icon>
          <v-icon>mdi-comment-quote</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Testimonials</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="deep-orange--text">STATISTICS</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item link v-on:click="$router.push('/program-surveys').catch(()=>{})">
        <v-list-item-icon>
          <v-icon>mdi-poll</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Program Surveys</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item link v-on:click="$router.push('/exit-poll-surveys').catch(()=>{})">
        <v-list-item-icon>
          <v-icon>mdi-google-analytics</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Exit Poll Surveys</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item link v-on:click="$router.push('/tracking-results').catch(()=>{})">
        <v-list-item-icon>
          <v-icon>mdi-home-analytics</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Tracking results</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="deep-orange--text">CONNECTS</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <!-- <v-list-item link v-on:click="$router.push('connects-config').catch(()=>{})">
        <v-list-item-icon>
          <v-icon>mdi-web</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Connects main</v-list-item-title>
        </v-list-item-content>
      </v-list-item> -->

      <v-list-item link v-on:click="$router.push('/connects').catch(()=>{})">
        <v-list-item-icon>
          <v-icon>mdi-web</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Connects</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <!-- <v-list-item link v-on:click="$router.push('coronary-experts').catch(()=>{})">
        <v-list-item-icon>
          <v-icon>mdi-account-cog</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Coronary Experts</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item link v-on:click="$router.push('gi-experts').catch(()=>{})">
        <v-list-item-icon>
          <v-icon>mdi-account-cog</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>GI Experts</v-list-item-title>
        </v-list-item-content>
      </v-list-item> -->
<!-- 
      <v-list-item link>
        <v-list-item-icon>
          <v-icon>mdi-gavel</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Admin</v-list-item-title>
        </v-list-item-content>
      </v-list-item> -->

      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="deep-orange--text">ACTIVE CAMPAIGN</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item link v-on:click="$router.push('campaigns').catch(()=>{})">
        <v-list-item-icon>
          <v-icon>mdi-google-ads</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Campaigns</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      
      <v-list-item link v-on:click="$router.push('ac-users').catch(()=>{})">
        <v-list-item-icon>
          <v-icon>mdi-account-group</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Users</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item link v-on:click="$router.push('ac-connects-lists').catch(()=>{})">
        <v-list-item-icon>
          <v-icon>mdi-cog</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Newsletter config</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

    </v-list>

    <template v-slot:append>
      <div class="pa-2" v-on:click="$store.dispatch('logoutUser')">
        <v-btn block style="background-color: #c7583b">Logout</v-btn>
      </div>
    </template>
  </v-navigation-drawer>
</template>

<script>
export default {
  name: "TheSidebar"
}
</script>
