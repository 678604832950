import Vue from 'vue'
import store from '@/store'
import Router from 'vue-router'

const Dashboard = () => import('@/views/Dashboard')
const Campaigns = () => import('@/views/activecampaign/Campaigns')
const ACUsers = () => import('@/views/activecampaign/ACUsers')
const ACConnectsLists = () => import('@/views/ACConnectsLists')
const Connects = () => import('@/views/Connects')
const ConnectsConfig = () => import('@/views/ConnectsConfig')
const Labels = () => import('@/views/Labels')
const ProgramSurveys = () => import('@/views/ProgramSurveys')
const ExitPollSurveys = () => import('@/views/ExitPollSurveys')
const PrivacyPolicyPage = () => import('@/views/PrivacyPolicyPage')
const MedicalAffairsPage = () => import('@/views/MedicalAffairsPage')
const AboutUsPage = () => import('@/views/AboutUsPage')
const Experts = () => import('@/views/Experts')
const Testimonials = () => import('@/views/Testimonials')
const TeamMembers = () => import('@/views/TeamMembers')
const Partnerships = () => import('@/views/Partnerships')
const Collaborations = () => import('@/views/Collaborations')
const Users = () => import('@/views/Users')
const Accreditations = () => import('@/views/Accreditations')
const TherapeuticAreas = () => import('@/views/TherapeuticAreas')
const TrackingResults = () => import('@/views/TrackingResults')
const AuditLogs = () => import('@/views/AuditLogs')
const Programmes = () => import('@/views/Programmes')
const Login = () => import('@/views/pages/Login')
const ConnectExperts = () => import('@/views/ConnectExperts')
const ConnectProgrammes = () => import('@/views/ConnectProgrammes')
const SurveyAnswers = () => import('@/views/SurveyAnswers')
const ProgramSurveyAnswers = () => import('@/views/ProgramSurveyAnswers')
// const CoronaryExperts = () => import('@/views/ExpertsTEST_1')
// const GiExperts = () => import('@/views/ExpertsTEST_2')

Vue.use(Router)

const publicRoutes = new Router({
  mode: 'history',
  linkActiveClass: 'active',
  scrollBehavior: () => ({ y: 0 }),
  routes: configRoutes()
})

function configRoutes () {
  return [
    {
      path: '/',
      redirect: '/dashboard',
      name: 'Home',
    },
    {
      path: '/dashboard',
      name: 'Dashboard',
      component: Dashboard,
    },
    {
      path: '/connects',
      name: 'Connects',
      component: Connects,
    },
    {
      path: '/campaigns',
      name: 'Campaigns',
      component: Campaigns,
    },
    {
      path: '/ac-users',
      name: 'ACUsers',
      component: ACUsers,
    },
    {
      path: '/ac-connects-lists',
      name: 'ACConnectsLists',
      component: ACConnectsLists,
    },
    {
      path: '/connects-config',
      name: 'ConnectsConfig',
      component: ConnectsConfig,
    },
    {
      path: '/connect-experts/:websiteId',
      name: 'ConnectExperts',
      component: ConnectExperts,
    },
    {
      path: '/connect-programmes/:websiteId',
      name: 'ConnectProgrammes',
      component: ConnectProgrammes,
    },
    {
      path: '/labels',
      name: 'Labels',
      component: Labels,
    },
    {
      path: '/program-surveys',
      name: 'ProgramSurveys',
      component: ProgramSurveys,
    },
    {
      path: '/exit-poll-surveys',
      name: 'ExitPollSurveys',
      component: ExitPollSurveys,
    },
    {
      path: '/experts',
      name: 'Experts',
      component: Experts,
    },
    {
      path: '/testimonials',
      name: 'Testimonials',
      component: Testimonials,
    },
    {
      path: '/team-members',
      name: 'TeamMembers',
      component: TeamMembers,
    },
    {
      path: '/users',
      name: 'Users',
      component: Users,
    },
    {
      path: '/survey-answers/:id',
      name: 'SurveyAnswers',
      component: SurveyAnswers,
    },
    {
      path: '/program-survey-answers',
      name: 'ProgramSurveyAnswers',
      component: ProgramSurveyAnswers,
    },
    {
      path: '/accreditations',
      name: 'Accreditations',
      component: Accreditations,
    },
    {
      path: '/partnerships',
      name: 'Partnerships',
      component: Partnerships,
    },
    {
      path: '/collaborations',
      name: 'Collaborations',
      component: Collaborations,
    },
    {
      path: '/privacy-policy-page',
      name: 'PrivacyPolicyPage',
      component: PrivacyPolicyPage,
    },
    {
      path: '/medical-affairs-page',
      name: 'MedicalAffairsPage',
      component: MedicalAffairsPage,
    },
    {
      path: '/about-us-page',
      name: 'AboutUsPage',
      component: AboutUsPage,
    },
    {
      path: '/therapeutic-area',
      name: 'TherapeuticAreas',
      component: TherapeuticAreas,
    },
    {
      path: '/tracking-results',
      name: 'TrackingResults',
      component: TrackingResults,
    },
    {
      path: '/audit-logs',
      name: 'AuditLogs',
      component: AuditLogs,
    },
    {
      path: '/programmes',
      name: 'Programmes',
      component: Programmes,
    },
    {
      path: '/pages',
      redirect: '/pages/404',
      name: 'Pages',
      component: {
        render (c) { return c('router-view') }
      },
      children: [
        {
          path: 'login',
          name: 'Login',
          component: Login
        }
      ]
    }
  ]
}

publicRoutes.beforeEach((from, to, next) => {
  // console.log('before each', from, to)
  let token = localStorage.getItem('token')
  if (token) {
    let tokenData = token.split('.')[1]
    let decodedTokenJsonData = window.atob(tokenData)
    let expiry_date = JSON.parse(decodedTokenJsonData).exp * 1000
    let now = new Date().getTime()
    if (expiry_date < now) {
      store.dispatch("logoutUser")
    }
  } else {
    if (window.location.pathname != "/pages/login") {
      window.location = "/pages/login"
    }
  }
  next();
});

export default publicRoutes