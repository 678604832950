<template>
  <v-app>
    <v-main>
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>
import TheSidebar from "@/components/TheSidebar";
export default {
  name: "App",
  components: {
    TheSidebar,
  },
};
</script>

<style lang="scss">
html{
  overflow: auto;
}
.v-main__wrap {
  display: flex;
}
.page-wrapper {
  overflow: hidden;
  display: flex;
  width: 100vw;
  height: 100vh;
  justify-content: flex-start;
  align-items: flex-start;
}
.right-content-wrapper{
  overflow: auto;
  height: 100vh;
  width: 100%;
}
.row .v-list.v-sheet .v-list-item.v-list-item--link.theme--light {
  background-color: white;
  margin: 4px;
  border: 2px solid gray;
  border-radius: 30px;
  padding: 0px 13px;
}
.circular-wrapper{
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 80vh;
}
</style>
