import Api from '@/services/Api'

export function register(params) {
    return Api().post('/register', params);
}

export function login(params) {
    return Api().post('/login', { "email": params.email, "password": params.password });
}

export function forgotPassword(params) {
    return Api().post('/forgot-password', params);
}

export function resetPassword(params, uid) {
    return Api().post(`/change-password/${uid}`, { "password": params });
}

export function verifyCode(code) {
    return Api().post(`/verify-code/${code}`);
}