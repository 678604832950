
export function getLocalStorageToken() {
    let token = window.localStorage.getItem('token')
    return token;
}

export function setLocalStorageToken(token) {
    window.localStorage.token = token;
    return;
}

export function getLocalStorageUser() {
    if (window.localStorage.user) {
        let user = JSON.parse(window.localStorage.user);
        return user;
    } else {
        return {}
    }
}

export function setLocalStorageUser(user) {
    window.localStorage.user = JSON.stringify(user);
    return;
}

export function localStorageLogout() {
    window.localStorage.removeItem('user');
    window.localStorage.removeItem('token');
    window.location = "/"
}