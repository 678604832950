import Api from '@/services/Api'

// CMS DASHBOARD
export function fetchSummaryGraphs() {
  return Api().get(`/dashboard`)
}
// WEBSITES / SUBDOMAINS
export function fetchWebsites() {
  return Api().get(`/cms-websites`)
}
export function addWebsite(data) {
  return Api().post(`/config-website`, data)
}
export function editWebsite(data) {
  return Api().post(`/config-website`, data)
}
export function deleteWebsite(data) {
  return Api().delete(`/config-website/${data.id}`)
}

// LABELS
export function fetchLabels(data) {
  return Api().post('/labels/all', data)
}
export function fetchAllLables() {
  return Api().get('/labels')
}
export function addLabel(data) {
  return Api().post(`/labels`, data)
}
export function editLabel(data) {
  return Api().post(`/labels/${data.id}`, data)
}
export function deleteLabel(data) {
  return Api().delete(`/labels/${data.id}`)
}

// PROGRAM SURVEYS
export function fetchProgramSurveys(data) {
  return Api().post('/program-survey/all', data)
}
export function addProgramSurvey(data) {
  return Api().post('/survey', data)
}
export function editProgramSurvey(data) {
  return Api().post(`/survey/${data.id}`, data)
}
export function deleteProgramSurvey(data) {
  return Api().delete(`/survey/${data.id}`, data)
}
export function fetchProgramSurveyQuestions(data) {
  return Api().get(`/survey/${data.id}`)
}
export function updateProgramSurveyQuestionsAPI(id, data) {
  let new_data = []
  data.forEach(element => { new_data.push({ title: element.title, id: element.id }) })
  return Api().post('/question/update', { title: new_data, survey_id: id })
}
export function activateDeactivateProgramSurvey(id) {
  return Api().post('/program-survey/activate-deactivate', { is_activated: 1, uid: id })
}
export function activateDeactivateExitPollSurvey(id) {
  return Api().post('/exit-polls-survey/activate-deactivate', { is_activated: 1, uid: id })
}

// EXIT POLL SURVEYS
export function fetchExitPollSurveys(data) {
  return Api().post('/exit-polls-survey/all', data)
}
export function addExitPollSurvey(data) {
  return Api().post('/survey', data)
}
export function editExitPollSurvey(data) {
  return Api().post(`/survey/${data.id}`, data)
}
export function deleteExitPollSurvey(data) {
  return Api().delete(`/survey/${data.id}`, data)
}
export function fetchExitPollSurveyQuestions(data) {
  return Api().get(`/survey/${data.id}`)
}
export function updateExitPollSurveyQuestionsAPI(id, data) {
  let new_data = []
  data.forEach(element => { new_data.push({ title: element.title, id: element.id }) })
  return Api().post('/question/update', { title: new_data, survey_id: id })
}

// PROGRAMME
export function publishUnpublishProgram(data) {
  return Api().post(`/programmes/enable-disable`, { uid: data.id, status: data.status === true ? 1 : 0 })
}
export function fetchProgrammesForConnect(data) {
  return Api().post(`/programmes/website-all`, data)
}

// USERS
export function fetchUsers(data) {
  return Api().post('/all-users', data)
}

// EXPERTS
export function fetchExperts(data) {
  return Api().post('/expert_managements/all', data)
}
export function fetchExpertsForConnect(data) {
  return Api().post('/expert_managements/all-website', data)
}
export function fetchAllExperts() {
  return Api().get('/expert_managements')
}
export function showExpert(data) {
  return Api().get(`/expert_managements/${data.id}`)
}
export function addExpert(data) {
  return Api().post(`/expert_managements`, data)
}
export function editExpert(data) {
  return Api().post(`/expert_managements/${data.id}`, data)
}
export function deleteExpert(data) {
  return Api().delete(`/expert_managements/${data.id}`)
}
export function setExpertType(data) {
  return Api().post(`/expert_managements/website`, data)
}
export function fetchCountries() {
  return Api().get(`/countries`)
}
export function editConnectExpertOrder(data) {
  return Api().post(`/expert_managements/website-sort`, data)
}

// TEAM MEMBERS
export function fetchTeamMembers(data) {
  return Api().post('/team_members/all', data)
}
export function showTeamMember(data) {
  return Api().get(`/team_members/${data.id}`)
}
export function addTeamMember(data) {
  return Api().post(`/team_members`, data)
}
export function editTeamMember(data) {
  return Api().post(`/team_members/${data.id}`, data)
}
export function deleteTeamMember(data) {
  return Api().delete(`/team_members/${data.id}`)
}

// ACREDITATIONS
export function fetchAccreditations(data) {
  return Api().post('/accreditation/all', data)
}
export function fetchAllAccreditations() {
  return Api().get('/accreditation')
}
export function showAccreditation(data) {
  return Api().get(`/accreditation/${data.id}`)
}
export function addAccreditation(data) {
  return Api().post(`/accreditation`, data)
}
export function editAccreditation(data) {
  return Api().post(`/accreditation/${data.id}`, data)
}
export function deleteAccreditation(data) {
  return Api().delete(`/accreditation/${data.id}`)
}

// Therapeutic Areas
export function fetchTherapeuticAreas(data) {
  return Api().post('/therapeutic_area/all', data)
}
export function fetchAllTherapeuticAreas() {
  return Api().get('/therapeutic_area',)
}
export function showTherapeuticArea(data) {
  return Api().get(`/therapeutic_area/cms/${data.id}`)
}
export function addTherapeuticArea(data) {
  return Api().post(`/therapeutic_area`, data)
}
export function editTherapeuticArea(data) {
  return Api().post(`/therapeutic_area/${data.id}`, data)
}
export function deleteTherapeuticArea(data) {
  return Api().delete(`/therapeutic_area/${data.id}`)
}

// PROGRAMMES
export function fetchProgrammes(data) {
  return Api().post('/programmes/all', data)
}
export function fetchProgrammesDropdown(data) {
  return Api().get('/programmes/drop-down', data)
}
export function showProgramme(data) {
  return Api().get(`/programmes/${data.slug}`)
}
export function addProgramme(data) {
  return Api().post(`/programmes`, data)
}
export function editProgramme(data) {
  return Api().post(`/programmes/${data.id}`, data)
}
export function deleteProgramme(data) {
  return Api().delete(`/programmes/${data.id}`)
}

// DOCUMENTS
export function fetchDocs(data) {
  return Api().post('/docs/all', data)
}
export function fetchAllDocs() {
  return Api().get('/docs')
}
export function addDoc(data) {
  return Api().post('/docs', data)
}
export function deleteDoc(data) {
  return Api().delete(`/docs/${data.id}`)
}

// PARTNERSHIPS

export function fetchPartnerships(data) {
  return Api().post('/partnership/all', data)
}
export function addPartnership(data) {
  return Api().post('/partnership', data)
}
export function editPartnership(data) {
  return Api().post(`/partnership/${data.id}`, data)
}
export function deletePartnership(data) {
  return Api().delete(`/partnership/${data.id}`)
}

// COLLABORATIONS

export function fetchCollaborations(data) {
  return Api().post('/collaborations/all', data)
}
export function addCollaboration(data) {
  return Api().post('/collaborations', data)
}
export function editCollaboration(data) {
  return Api().post(`/collaborations/${data.id}`, data)
}
export function deleteCollaboration(data) {
  return Api().delete(`/collaborations/${data.id}`)
}

// ANSWERS for SURVEYS
export function fetchConnectAnswers(id) {
  return Api().post(`/website/program`, { website_id: id })
}
export function fetchSurveyAnswers(data) {
  return Api().post(`/website/program-answer`, data)
}

export function fetchAuditLogs(data) {
  return Api().post(`/all-activity-logs`, data)
}

// TRACKING RESULTS
export function fetchTrackingResults(data) {
  return Api().post(`/tracking-results`, data)
}

// SPECIALITIES
export function fetchSpecialities() {
  return Api().get(`/speciality-cms`)
}
export function activateDeactivateListSpecialityAPI(data) {
  return Api().post(`/speciality-cms-add`, data)
}

// AC
export function fetchACLists() {
  return Api().get(`/activecampaign-lists`)
}

// "COMMON" PAGES
export function fetchPrivacyPolicyPage() {
  return Api().get(`/privacy-policy`)
}

export function updatePrivacyPolicyPage(data) {
  return Api().post(`/privacy-policy`, data)
}

export function fetchMedicalAffairsPage() {
  return Api().get(`/medical-affair`)
}

export function updateMedicalAffairsPage(data) {
  return Api().post(`/website/medical-affair`, data)
}

export function editWebsiteCollaborations(data) {
  return Api().post(`/collaborations/website`, data)
}

export function editWebsiteContactInformation(data) {
  return Api().post(`/website/contact`, data)
}

export function editWebsiteMedicalAffairs(data) {
  return Api().post(`/website/medical-affair`, data)
}

export function fetchAboutUsPage() {
  return Api().get(`/about_us`)
}

export function updateAboutUsPage(data) {
  return Api().post(`/about_us/${data.id}`, data)
}

// TESTIMONIALS
export function fetchTestimonials() {
  return Api().post(`/testimonials/all`)
}
export function addTestimonial(data) {
  return Api().post(`/testimonials`, data)
}
export function editTestimonial(data) {
  return Api().post(`/testimonials/${data.id}`, data)
}
export function deleteTestimonial(data) {
  return Api().delete(`/testimonials/${data.id}`)
}